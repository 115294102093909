import { Routes, Route, Navigate } from 'react-router-dom';
import SystemAdministration from 'src/screens/SystemAdministration';
import PageSettings from 'src/screens/PageSettings';
import Home from 'src/screens/Home';
import Report from 'src/screens/Report';
import PersonalSettings from 'src/screens/PersonalSettings';
import LayoutPage from 'src/components/LayoutPage';
import LegacyPage from 'src/components/LegacyPage';
import ScreenHeader from 'src/components/ScreenHeader';
import { APP_URL } from 'src/consts/cookies';
import TimeTrackerTestComponent from 'src/utils/time/timeTrackerTime/TimeTrackerTestComponent';
import PageRoute from 'src/routers/PageRoute';
import LegacyRoute from 'src/routers/LegacyRoute';
import SamplePathRoute from 'src/routers/SamplePathRoute';
import { isDev } from 'src/utils/env';
import {
  systemAdministrationRoutes,
  personalSettingsRoutes,
  pageSettingsRoutes,
  editProjectTemplateRoutes,
} from 'src/consts/paths';
import Cookies from 'js-cookie';
import { designSevenPagePath } from 'src/designSevenPagePath';
import AuthRoute from '../AuthRoute';
import ProjectsRouter from '../ProjectsRouter';

export const administrativeRoutes = systemAdministrationRoutes
  .concat(personalSettingsRoutes)
  .concat(pageSettingsRoutes);

const LogOff = () => {
  window.location.href = `${Cookies.get(APP_URL)}/authentication/logoff`;
  return null;
};

export const MainRouter = () => (
  <Routes>
    {personalSettingsRoutes?.map((url) => (
      <Route
        key={url}
        path={url}
        element={
          <AuthRoute>
            <PersonalSettings />
          </AuthRoute>
        }
      />
    ))}

    {/* Must be put before system administration route for edit project template */}
    {editProjectTemplateRoutes?.map((url) => (
      <Route
        key={url}
        path={url}
        element={
          <AuthRoute>
            <LayoutPage>
              <LegacyPage data-automation-id="LegacyPage" />
            </LayoutPage>
          </AuthRoute>
        }
      />
    ))}

    {systemAdministrationRoutes?.map((url) => (
      <Route
        key={url}
        path={url}
        element={
          <AuthRoute>
            <SystemAdministration />
          </AuthRoute>
        }
      />
    ))}

    {pageSettingsRoutes?.map((url) => (
      <Route
        key={url}
        path={url}
        element={
          <AuthRoute>
            <PageSettings />
          </AuthRoute>
        }
      />
    ))}

    {isDev && (
      <Route path="/test/utils/time/timeTrackerTime" element={<TimeTrackerTestComponent />} />
    )}

    {isDev && <Route path={designSevenPagePath.sample.root} element={<SamplePathRoute />} />}

    {/* Route for log out */}
    <Route path="/logoff" element={<LogOff />} />

    <Route
      path="/"
      element={
        <AuthRoute>
          <LayoutPage />
        </AuthRoute>
      }
    >
      <Route
        path={designSevenPagePath.home}
        element={
          <PageRoute
            pageTitle="Home"
            pageId="5f11ec8e-27f0-47b2-9bb2-667ed9f4800c"
            pageIdentifier="DesignSevenFrontPage"
          >
            <Home />
          </PageRoute>
        }
      />

      <Route
        path={designSevenPagePath.reports.root}
        caseSensitive
        element={
          <Routes>
            <Route
              path={designSevenPagePath.reports.sampleReport}
              element={
                <PageRoute
                  pageTitle="Reports"
                  pageId="5f11ec8e-27f0-47b2-9bb2-667ed9f4800d"
                  pageIdentifier="DesignSevenReports"
                >
                  <>
                    <ScreenHeader />
                    <Report />
                  </>
                </PageRoute>
              }
            />
          </Routes>
        }
      />

      <Route path={designSevenPagePath.projects.root} element={<ProjectsRouter />} />

      <Route
        path={designSevenPagePath.employees.root}
        element={
          <Routes>
            <Route
              path={designSevenPagePath.employees.resourcePlannerOldUrl}
              element={
                <Navigate
                  to={`${designSevenPagePath.projects.root.replace('*', '')}${
                    designSevenPagePath.projects.resourcePlanner
                  }`}
                  replace
                />
              }
            />
          </Routes>
        }
      />

      <Route
        path="*"
        element={
          <LegacyRoute>
            <LegacyPage data-automation-id="LegacyPage" />
          </LegacyRoute>
        }
      />
    </Route>
  </Routes>
);
